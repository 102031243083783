interface InternalLink {
  slug: {
    current: string;
  };
}

export function linkDestination(
  internal?: InternalLink | null,
  external?: string
): string | undefined {
  if(!external && !internal) {
    return undefined;
  }
  if (internal?.slug?.current) {
    return '/' + internal.slug.current;
  }
  if (external) {
    return external;
  }
  return undefined;
}
