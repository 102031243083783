import media from "css-in-js-media";
import { Link } from "gatsby";
import * as React from "react";
import { FaInstagram, FaLinkedin } from "react-icons/fa";
import styled from "styled-components";
import eoa from "../../images/accreditation/eoa.png";
import iip from "../../images/accreditation/investors-in-people.png";
import iso from "../../images/accreditation/iso.png";
import isoqar from "../../images/accreditation/isoqar.png";
import megabuyte from "../../images/accreditation/megabuyte.png";
import Logo from "../../images/icons/logo.svg";

const StyledFooter = styled.footer`
  display: grid;
  grid-template-columns: 1fr;

  ${media(">desktop")} {
    grid-template-columns: repeat(6, 1fr);
  }
`;

const StyledFooterBody = styled.div`
  padding: 40px 30px;
  grid-column: 1 / -1;
  background-color: var(--colour-grey-1);
  color: white;
  display: flex;
  flex-direction: column;

  ${media(">tablet")} {
    grid-column: span 4;
  }

  svg {
    width: 40px;
    height: 40px;
  }
`;

const StyledFooterSocials = styled.div`
  display: flex;
  gap: 1rem;
  margin: 30px 0;
`;

const StyledFooterSidebar = styled.div`
  order: -1;
  display: flex;
  flex-direction: column;
  grid-column: 1 / -1;
  background-color: #d9d9d9;

  ${media(">tablet", "<desktop")} {
    flex-direction: row;
    grid-column: span 2;
  }

  ${media(">desktop")} {
    order: 0;
    flex-direction: column;
    grid-column: span 2;
  }
`;

const StyledFooterConsultation = styled.div`
  position: relative;
  overflow: hidden;
  background-color: var(--colour-yellow);
  padding: 40px 30px;

  ${media(">tablet", "<desktop")} {
    width: 50%;
  }

  h2 {
    margin: 0 0 40px 0;
    font-size: 26px;
    font-weight: 500;
  }

  a {
    color: inherit;
    font-size: 26px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .logo {
    fill: rgba(0, 0, 0, 0.1);
    position: absolute;
    top: 0;
    right: -150px;
    height: 100%;
    width: auto;
    transform: scale(1.2);
    pointer-events: none;
  }
`;

const StyledFooterNewsletter = styled.div`
  position: relative;
  overflow: hidden;
  background-color: #d9d9d9;
  padding: 40px 30px;

  ${media(">tablet", "<desktop")} {
    width: 50%;
  }

  h2 {
    margin: 0 0 40px 0;
    font-size: 26px;
    font-weight: 500;
  }

  a {
    color: inherit;
    font-size: 26px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const StyledTel = styled.a`
  display: block;
  font-size: 30px;
  text-decoration: none;
`;

const StyledEmail = styled.a`
  display: inline-block;
  font-size: 28px;
  text-decoration: underline;
  word-break: break-word;
`;

const StyledAddress = styled.a`
  display: block;
  margin-top: 30px;
  color: #d9d9d9;
  font-size: 28px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledCopyright = styled.p`
  margin: 0;
  font-size: 14px;
  color: white;
`;

const StyledAccreditation = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: auto;
  margin-bottom: 30px;
  flex-wrap: wrap;

  img {
    width: auto;
    height: 50px;
  }
`;

const StyledLinks = styled.ul`
  display: flex;
  gap: 0.5rem;
  list-style: none;
  margin: 10px 0 0;
  padding: 0;
  flex-wrap: wrap;
  font-size: 14px;
`;

interface FooterProps {
  links: any[];
}

export function Footer({ links }: FooterProps) {
  return (
    <StyledFooter>
      <StyledFooterBody>
        <div>
          <StyledTel href="tel:01132010600">0113 201 0600</StyledTel>
          <StyledEmail href="mailto:enquiries@answerdigital.com">
            enquiries@answerdigital.com
          </StyledEmail>
          <StyledAddress href="https://www.google.com/maps/search/Union+Mills,+9+Dewsbury+Road,+Leeds,+LS11+5DD/@53.7887855,-1.546023,17z/data=!3m1!4b1">
            Union Mills, 9 Dewsbury Road, LS11 5DD
          </StyledAddress>
          <StyledFooterSocials>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/answerdigital/"
              aria-label="LinkedIn"
            >
              <FaLinkedin />
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/answerdigital/"
              aria-label="Instagram"
            >
              <FaInstagram />
            </a>
          </StyledFooterSocials>
        </div>
        <StyledAccreditation>
          <img src={iso} alt="ISO 27001:2013 Certified Company" />
          <img src={isoqar} alt="ISOQAR Registered" />
          <img src={megabuyte} alt="Megabuyte emerging 50 2024 peer group winner" />
          <img src={iip} alt="Investors in People Gold" />
          <img src={eoa} alt="Employee Ownership Association" />
        </StyledAccreditation>

        <StyledCopyright>
          Answer Digital Limited. Registered in England and Wales. Company number: 3655429.
        </StyledCopyright>
        <StyledLinks>
          {links.map((link) => (
            <li key={link.id}>
              <Link to={link.destination}>{link.title}</Link>
            </li>
          ))}
        </StyledLinks>
      </StyledFooterBody>
      <StyledFooterSidebar>
        <StyledFooterConsultation>
          <h2>
            <strong>Grow with us.</strong> Book a free consultation today
          </h2>
          <Link to="/contact-us">&gt; Get in touch</Link>
          <Logo className="logo" />
        </StyledFooterConsultation>
        <StyledFooterNewsletter>
          <h2>Stay ahead of the latest news and events.</h2>
          <a href="https://mailchi.mp/7212b21d068c/b6ouwtx2gg">&gt; Newsletter sign up</a>
        </StyledFooterNewsletter>
      </StyledFooterSidebar>
    </StyledFooter>
  );
}
