import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { NavigationMenu } from "radix-ui";
import React from "react";
import styled from "styled-components";
import Logo from "../../images/icons/logo.svg";
import { ButtonLink } from "../ButtonLink/ButtonLink";
import { HeaderProps, NavItemProps } from "./Header";

const Container = styled(NavigationMenu.Root)`
  padding: 0 1rem;
  max-width: var(--layout-max-content-width);
  margin: 0 auto;
  width: 100%;

  display: flex;
  align-items: center;

  position: relative;

  z-index: 999;

  .NavigationMenuContent {
    position: absolute;
    top: 0;
    width: 100%;
  }

  .NavigationMenuViewport {
    position: absolute;
    margin-top: 0.5rem;
    background-color: #d9d9d9;
    overflow: hidden;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    max-width: min(calc(100vw - 2rem), var(--layout-max-content-width));
    width: auto;
  }

  .NavigationMenuViewport[data-state="open"] {
    display: block;
  }

  .NavigationMenuViewport[data-state="closed"] {
    display: none;
  }

  .NavigationMenuIndicator {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 10px;
    top: 100%;
    overflow: hidden;
    z-index: 1;
  }

  .NavigationMenuIndicator[data-state="visible"] {
    display: block;
  }

  .NavigationMenuIndicator[data-state="hidden"] {
    display: none;
  }

  .ViewportPosition {
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    right: 0;
  }
`;

const StyledNav = styled.nav`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  font-size: 18px;

  ul {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
  }

  li {
    list-style: none;
    position: relative;
  }

  > a {
    display: none;

    @media only screen and (min-width: 1160px) {
      display: inherit;
    }
  }
`;

const StyledLink = styled(Link)`
  color: var(--colour-white);
  text-decoration: none;
  padding: 1.8rem 0.75rem;

  &:hover {
    text-decoration: underline;
  }

  &:focus-visible {
    outline: 2px solid white;
    outline-offset: 2px;
  }
`;

const StyledTrigger = styled(NavigationMenu.Trigger)`
  all: unset;
  color: var(--colour-white);
  text-decoration: none;
  cursor: pointer;
  padding: 1.8rem 0.75rem;

  &:hover,
  &[data-state="open"] {
    text-decoration: underline;
  }

  &:focus-visible {
    outline: 2px solid white;
    outline-offset: 2px;
  }
`;

const StyledDropdownWrapper = styled.div`
  display: flex;
  max-width: 100%;

  > div:last-child {
    padding: 2rem;
  }
`;

const StyledDropdownDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  background-color: var(--colour-yellow);
  width: 340px;
  max-width: 100%;

  h2 {
    font-size: 24px;
    margin: 0 0 0.5rem 0;
    font-weight: 400;
  }

  p {
    margin: 0;
    font-size: 14px;
    line-height: 1.5;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

const StyledImagePlaceholder = styled.div`
  width: 210px;
  height: 210px;
  background-color: var(--colour-dark-grey);
`;

const StyledDropdownContent = styled.div`
  max-width: 100%;
  overflow: auto;
`;

export const StyledLinkColumns = styled.div<{ $cols: number }>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.$cols}, 1fr);
  gap: 1rem;

  > div {
    min-width: 240px;
  }

  h2 {
    margin: 0 0 1rem 0;
    font-size: 22px;
  }

  ul {
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  li {
    list-style: none;
  }

  a {
    text-decoration: none;

    &:hover,
    &:focus-visible {
      text-decoration: underline;
    }

    &.heading {
      font-size: 22px;
      font-weight: 700;
    }
  }

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;

    > div {
      min-width: 0;
    }
  }
`;

const NavItem: React.FC<NavItemProps> = ({
  title,
  subNavigation,
  activeTrigger,
  setActiveTrigger,
  value,
  to,
}) => {
  const itemRef = React.useRef<HTMLLIElement>(null);

  React.useEffect(() => {
    if (title === value && itemRef.current) {
      const triggerElement = itemRef.current.querySelector("button");
      if (triggerElement && activeTrigger !== triggerElement) {
        setActiveTrigger(triggerElement);
      }
    }
  }, [title, value, activeTrigger, setActiveTrigger]);

  if (!subNavigation) {
    return (
      <NavigationMenu.Item value={title}>
        <StyledLink to={to}>{title}</StyledLink>
      </NavigationMenu.Item>
    );
  }

  return (
    <NavigationMenu.Item value={title} ref={itemRef}>
      <StyledTrigger>{title}</StyledTrigger>
      <NavigationMenu.Content>
        <StyledDropdownWrapper>
          {subNavigation.title && (
            <StyledDropdownDescription>
              {subNavigation.image && (
                <GatsbyImage
                  image={subNavigation.image.asset.gatsbyImageData}
                  alt={subNavigation.image.asset.altText}
                />
              )}
              <div>
                <h2>{subNavigation.title}</h2>
                <p>{subNavigation.description}</p>
              </div>
            </StyledDropdownDescription>
          )}
          <StyledDropdownContent>
            <StyledLinkColumns $cols={subNavigation.columns?.length ?? 1}>
              {subNavigation.columns?.map((column) => (
                <div key={column._key}>
                  {column.title && <h2>{column.title}</h2>}
                  <ul>
                    {column.links?.map((link) => (
                      <li key={link._key}>
                        <Link
                          to={
                            link.internalLink?.slug.current
                              ? `/${link.internalLink?.slug.current}`
                              : link.externalLink || link.customLink
                          }
                          className={link.variant ?? "standard"}
                        >
                          &gt; {link.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </StyledLinkColumns>
          </StyledDropdownContent>
        </StyledDropdownWrapper>
      </NavigationMenu.Content>
    </NavigationMenu.Item>
  );
};

export const DesktopHeader: React.FC<HeaderProps> = ({ navItems }) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const listRef = React.useRef<HTMLUListElement>(null);
  const viewportRef = React.useRef<HTMLDivElement>(null);
  const [value, setValue] = React.useState<string>();
  const [activeTrigger, setActiveTrigger] = React.useState<HTMLElement | null>(null);
  const [viewportPosition, setViewportPosition] = React.useState({ left: 0 });

  // Calculate correct position when active trigger or window size changes
  React.useEffect(() => {
    const positionViewport = () => {
      if (!activeTrigger || !viewportRef.current || !containerRef.current) return;

      // Get container dimensions and position
      const containerRect = containerRef.current.getBoundingClientRect();

      // Get trigger dimensions and position
      const triggerRect = activeTrigger.getBoundingClientRect();

      // Calculate trigger center point relative to container
      const triggerCenter = triggerRect.left + triggerRect.width / 2 - containerRect.left;

      // Get viewport width (once it's rendered)
      const viewportWidth = viewportRef.current.offsetWidth || 300; // Default if not yet rendered

      // Calculate the ideal left position to center the viewport under the trigger
      let idealLeft = triggerCenter - viewportWidth / 2;

      // Get the maximum allowed left position to keep the viewport within the container
      const maxLeft = containerRect.width - viewportWidth;

      // Keep the viewport within the container boundaries
      const adjustedLeft = Math.max(0, Math.min(idealLeft, maxLeft));

      setViewportPosition({ left: adjustedLeft });
    };

    // Position on mount and when active trigger changes
    positionViewport();

    // Also reposition on window resize
    window.addEventListener("resize", positionViewport);
    return () => window.removeEventListener("resize", positionViewport);
  }, [activeTrigger]);

  console.log({ navItems });

  return (
    <Container ref={containerRef} onValueChange={(newValue) => setValue(newValue)} value={value}>
      <Link to="/" aria-label="Answer">
        <Logo className="logo" />
      </Link>
      <StyledNav>
        <NavigationMenu.List ref={listRef}>
          {navItems.map((navItem) => (
            <NavItem
              key={navItem._key}
              {...navItem}
              to={
                navItem.internalLink?.slug.current
                  ? `/${navItem.internalLink?.slug.current}`
                  : navItem.externalLink || navItem.customLink
              }
              setActiveTrigger={setActiveTrigger}
              activeTrigger={activeTrigger!}
              value={value!}
            />
          ))}
          <NavigationMenu.Indicator className="NavigationMenuIndicator">
            <div className="Arrow" />
          </NavigationMenu.Indicator>
        </NavigationMenu.List>
        <ButtonLink
          link={{
            link: "/contact-us",
            linkVariant: "primary",
            linkInverse: false,
            linkTitle: " Book a consultation",
          }}
          variant="primary"
        >
          Book a consultation
        </ButtonLink>
      </StyledNav>
      <div className="ViewportPosition">
        <NavigationMenu.Viewport
          ref={viewportRef}
          className="NavigationMenuViewport"
          style={{
            left: `${viewportPosition.left}px`,
            top: "100%",
            height: "var(--radix-navigation-menu-viewport-height)",
            transition: "width 0.25s ease, height 0.25s ease",
          }}
        />
      </div>
    </Container>
  );
};
