import { graphql, useStaticQuery } from "gatsby";
import "normalize.css";
import * as React from "react";
import { GlobalStyle } from "../styles/global-styles";
import { transformMenuData } from "../utils/transformMenuData";
import { CookieBanner } from "./CookieBanner";
// import { Footer } from "./Footer";
import { Header } from "./Header/Header";
import { Footer } from "./common/Footer";

type LayoutProps = {
  transparentHeader: boolean;
  children?: React.ReactChildren;
};

export function Layout(props: LayoutProps): JSX.Element {
  const menuItems = useStaticQuery(graphql`
    fragment MenuItems on SanityMenuBuilderItem {
      titleOverride
      customLink
      linkDestination {
        id
        title
        slug {
          current
        }
      }
      _key
    }

    fragment NavItems on SanityNavigation {
      title
      _key
    }

    {
      sanitySiteSettings(_id: { eq: "siteSettings" }) {
        id
        navigation {
          _key
          title
          internalLink {
            slug {
              current
            }
          }
          customLink
          subNavigation {
            _key
            title
            description
            image {
              asset {
                gatsbyImageData(width: 300)
                altText
              }
            }
            columns {
              _key
              title
              links {
                _key
                title
                variant
                externalLink
                internalLink {
                  id
                  title
                  slug {
                    current
                  }
                }
              }
            }
          }
        }
        footerMenu1Items {
          ...MenuItems
        }
      }
    }
  `);

  const footerLinkSet1 = transformMenuData(menuItems.sanitySiteSettings?.footerMenu1Items);

  const { children, transparentHeader } = props;
  return (
    <>
      <GlobalStyle />
      <Header navItems={menuItems.sanitySiteSettings?.navigation} />
      {children}
      <Footer links={footerLinkSet1} />
      <CookieBanner />
    </>
  );
}
