import { Link } from "gatsby";
import React from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import styled from "styled-components";
import Logo from "../../images/icons/logo.svg";
import { ButtonLink } from "../ButtonLink/ButtonLink";
import { HeaderProps } from "./Header";

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  background: var(--colour-dark-grey);
  height: 70px;
  width: 100%;

  .logo {
    fill: var(--colour-yellow);
    width: 46px;
    height: 46px;
  }
`;

const StyledMobileMenu = styled.div`
  position: fixed;
  top: 70px;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  z-index: 999;

  padding: 2rem 0;

  background-color: #d9d9d9;
`;

const Container = styled.div`
  max-width: var(--layout-max-content-width);
  margin: 0 auto;
  width: 100%;
  padding-left: 1rem;

  display: flex;
  align-items: center;
`;

const StyledMenuButton = styled.button`
  margin-left: auto;
  background-color: transparent;
  border: none;
  height: 70px;
  width: 70px;
  cursor: pointer;

  svg {
    fill: var(--colour-yellow);
    width: 40px;
    height: 40px;
  }
`;

const StyledList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  a,
  button {
    border: 0;
    text-align: left;
    background-color: transparent;
    display: block;
    padding: 1rem 1rem;
    width: 100%;
    text-decoration: none;
    font-size: 22px;
    font-weight: 700;
    line-height: 1.1;
    color: var(--colour-dark-grey);
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const StyledButtonContainer = styled.div`
  padding: 1rem;
`;

export const MobileHeader: React.FC<HeaderProps> = ({ navItems }) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [activeSubMenu, setActiveSubMenu] = React.useState<number | null>(null);

  React.useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isMenuOpen]);

  return (
    <>
      <StyledHeader>
        <Container>
          <Link to="/" aria-label="Answer">
            <Logo className="logo" />
          </Link>
          <StyledMenuButton
            aria-label={isMenuOpen ? "Close menu" : "Open menu"}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? <AiOutlineClose /> : <AiOutlineMenu />}
          </StyledMenuButton>
        </Container>
      </StyledHeader>
      {isMenuOpen && (
        <StyledMobileMenu>
          <StyledList>
            {navItems.map((navItem, index) => (
              <li key={navItem._key}>
                {navItem.subNavigation ? (
                  <button onClick={() => setActiveSubMenu(index)}>&gt; {navItem.title}</button>
                ) : (
                  <Link
                    onClick={() => {
                      setActiveSubMenu(null);
                      setIsMenuOpen(false);
                    }}
                    to={
                      navItem.internalLink?.slug.current
                        ? `/${navItem.internalLink?.slug.current}`
                        : navItem.externalLink || navItem.customLink
                    }
                  >
                    &gt; {navItem.title}
                  </Link>
                )}
              </li>
            ))}
          </StyledList>
          {activeSubMenu !== null && (
            <StyledMobileMenu>
              <StyledList>
                <li>
                  <button onClick={() => setActiveSubMenu(null)}>
                    &lt; {navItems[activeSubMenu].title}
                  </button>
                </li>
                {navItems[activeSubMenu].subNavigation?.columns?.map((column) => (
                  <React.Fragment key={column._key}>
                    {column.links.map((link) => (
                      <li key={link._key}>
                        <Link
                          to={
                            link.internalLink?.slug.current
                              ? `/${link.internalLink?.slug.current}`
                              : link.externalLink || link.customLink
                          }
                          onClick={() => {
                            setActiveSubMenu(null);
                            setIsMenuOpen(false);
                          }}
                        >
                          &gt; {link.title}
                        </Link>
                      </li>
                    ))}
                  </React.Fragment>
                ))}
              </StyledList>
            </StyledMobileMenu>
          )}
          <StyledButtonContainer>
            <ButtonLink
              link={{
                link: "/contact-us",
                linkVariant: "primary",
                linkInverse: false,
                linkTitle: "Book a consultation",
              }}
              variant="primary"
            >
              Book a consultation
            </ButtonLink>
          </StyledButtonContainer>
        </StyledMobileMenu>
      )}
    </>
  );
};
