import { GatsbyLinkProps, Link } from "gatsby";
import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { linkDestination } from "../../utils/getLinkDestination";

type LinkVariant = "primary" | "secondary" | "tertiary";

export interface InternalLink {
  link: string;
  internalLink?: never;
  linkVariant?: LinkVariant;
  linkInverse?: boolean;
}

export interface ExternalLink {
  link?: never;
  internalLink?: {
    slug: {
      current: string;
    };
  };
  linkVariant?: LinkVariant;
  linkInverse?: boolean;
}

export interface ButtonLinkProps extends PropsWithChildren {
  link: InternalLink | ExternalLink;
  isInternal?: boolean;
  variant: LinkVariant;
  inverse?: boolean;
}

type StyledButtonProps = {
  $variant: ButtonLinkProps["variant"];
  $inverse: boolean;
};

export const StyledButton = styled.button<
  StyledButtonProps & { $variant: ButtonLinkProps["variant"]; $inverse: boolean }
>`
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  background-color: ${(props) =>
    props.$variant === "primary"
      ? props.$inverse
        ? "var(--colour-grey-1)"
        : "var(--colour-yellow)"
      : "transparent"};
  border: ${(props) =>
    props.$variant === "secondary" &&
    `2px solid ${props.$inverse ? "var(--colour-grey-1)" : "var(--colour-yellow)"}`};
  border-radius: 8px;
  gap: 8px;
  white-space: normal;
  margin: 0;
  height: 50px;
  cursor: pointer;
  padding: 0 32px;
  font-size: 16px;
  text-decoration: none;
  color: ${(props) =>
    props.$variant === "primary"
      ? props.$inverse
        ? "var(--colour-white)"
        : "var(--colour-grey-1)"
      : props.$variant === "secondary"
      ? props.$inverse
        ? "var(--colour-grey-1)"
        : "var(--colour-white)"
      : "var(--colour-grey-1)"};

  &:hover {
    text-decoration: underline;
  }

  &:focus-visible {
    outline: 2px solid var(--colour-grey-1);
    outline-offset: 2px;
  }
`;

export const StyledButtonLink = styled(StyledButton).attrs<GatsbyLinkProps<any>>({
  as: Link,
})<StyledButtonProps>``;

export const ButtonLink: React.FC<ButtonLinkProps> = ({
  link,
  children,
  variant = "primary",
  inverse = false,
}) => {
  const to = linkDestination(link.internalLink, link.link) || "";

  return (
    <StyledButtonLink $variant={variant} $inverse={inverse} to={to as string}>
      {children}
    </StyledButtonLink>
  );
};
