import React from "react";
import styled from "styled-components";
import { useMediaQuery } from "usehooks-ts";
import { DesktopHeader } from "./DesktopHeader";
import { MobileHeader } from "./MobileHeader";

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  background: var(--colour-dark-grey);

  .logo {
    fill: var(--colour-yellow);
    width: 46px;
    height: 46px;
  }
`;

export interface SubNavColumn {
  _key: string;
  title: string;
  links: {
    _key: string;
    title: string;
    variant: string;
    internalLink: {
      slug: {
        current: string;
      };
    };
    externalLink: string;
    customLink: string;
  }[];
}

export interface SubNav {
  title?: string;
  description?: string;
  image?: {
    asset: {
      gatsbyImageData: any;
      altText: string;
    };
  };
  columns?: SubNavColumn[];
}

export interface NavItem {
  _key: string;
  title: string;
  subNavigation?: SubNav;
  internalLink: {
    slug: {
      current: string;
    };
  };
  externalLink: string;
  customLink: string;
}

export interface HeaderProps {
  navItems: NavItem[];
}

export interface NavItemProps {
  title: string;
  _key: string;
  to: string;
  activeTrigger: HTMLElement;
  setActiveTrigger: (node: HTMLElement) => void;
  value: string;
  subNavigation?: SubNav;
}

export const Header: React.FC<HeaderProps> = ({ navItems }) => {
  const isMobile = useMediaQuery("(max-width: 960px)");

  return (
    <StyledHeader>
      {isMobile ? <MobileHeader navItems={navItems} /> : <DesktopHeader navItems={navItems} />}
    </StyledHeader>
  );
};
